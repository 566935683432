import Image from 'next/image';
import Link from 'next/link';


const Footer = () => {
  return (
    <footer className="bg-[#A6BE9A] m-0 p-0">
      <div className="container">
        <div className="py-12 md:flex">
          <Link href="/" className="w-fit h-fit">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo-w.png"}
              alt="greenparenting white logo"
              quality={100}
              width={844}
              height={313}
              className="min-w-[140px] w-[150px] md:min-w-[180px] md:w-[200px] h-auto"
            />
          </Link>
          <div className="grid grid-cols-2 md:flex md:justify-between md:ml-[200px] w-full text-white font-dmsans md:justify-items-end">
            <div className="mt-12 md:mt-0">
              <div className="text-sm font-medium md:text-base">
                <Link href="/about-us" >
                  About us
                </Link>
              </div>
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/partner" >
                  Be Our Partner
                </Link>
              </div>
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/contact-us" >
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div className="text-sm font-medium md:text-base">
                <Link href="/delivery-information">
                  Delivery Information
                </Link>
              </div>
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/return-and-exchange">
                  Return and Exchange
                </Link>
              </div>
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div className="text-sm font-medium md:text-base">
                <Link href="/user">
                  My Profile
                </Link>
              </div>
              {/* <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="">
                  Refer a Friend
                </Link>
              </div> */}
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/signup">
                  Create Account
                </Link>
              </div>
              <div className="mt-4 text-sm font-medium md:text-base">
                <Link href="/faq">
                  FAQ
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-full border-t-[0.5px] border-white opacity-50"></div>
        <div className="grid grid-cols-2 mb-12 md:my-8 md:flex md:justify-center md:space-x-2">
          <div className="hidden md:block">
            <Link href="/brand/mooimom">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-mooimom-white-middle.png"}
                alt="mooimom white logo"
                width={134}
                height={56}
                quality={100}
              />
            </Link>
          </div>
          <div className="hidden md:block">
            <Link href="/brand/mugu">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-mugu-white-middle.png"}
                alt="mugu white logo"
                width={134}
                height={56}
                quality={100}
              />
            </Link>
          </div>
          <div className="hidden md:block">
            <Link href="/brand/nero-bianco">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-nero-bianco-white-middle.png"}
                alt="nero bianco white logo"
                width={134}
                height={56}
                quality={100}
              />
            </Link>
          </div>
          <div className="hidden md:block">
            <Link href="/brand/tweeling">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-tweeling-white-middle.png"}
                alt="tweeling white logo"
                width={134}
                height={56}
                quality={100}
              />
            </Link>
          </div>
          <div className="md:hidden">
            <Link href="/brand/mooimom">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-mooimom-white-middle-left.png"}
                alt="mooimom white logo"
                width={500}
                height={245}
                quality={100}
              />
            </Link>
          </div>
          <div className="md:hidden">
            <Link href="/brand/mugu">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-mugu-white-middle-left.png"}
                alt="mugu white logo"
                width={500}
                height={245}
                quality={100}
              />
            </Link>
          </div>
          <div className="md:hidden">
            <Link href="/brand/nero-bianco">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-nero-bianco-white-middle-left.png"}
                alt="nero bianco white logo"
                width={500}
                height={245}
                quality={100}
              />
            </Link>
          </div>
          <div className="md:hidden">
            <Link href="/brand/tweeling">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-tweeling-white-middle-left.png"}
                alt="tweeling white logo"
                width={500}
                height={245}
                quality={100}
              />
            </Link>
          </div>
        </div>
        <div className="flex justify-center pb-12 text-[10px] md:text-sm text-white font-montserrat font-normal">
          2024 © Green Parenting PTY. LTD. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
