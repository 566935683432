import Image from 'next/image';
import Link from 'next/link';
import { InstagramItemProps } from './constants';
import Cookies from 'js-cookie';


interface InstagramProps {
  instagram: Array<InstagramItemProps>;
  title: string;
  mobile_title: string;
}

const Instagram = (props: InstagramProps) => {
  const { instagram, title, mobile_title } = props;
  const viewport = Cookies.get('viewport');

  return (
    <div className="container mb-12 md:mb-20">
      <h3
        className="text-[20px] md:text-[32px] font-dmsans font-medium text-[#4D4D4D] mb-12 text-center md:text-left"
        dangerouslySetInnerHTML={{__html: viewport === "desktop" ? title : mobile_title}}
      ></h3>
      <div className="min-h-[200px] grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-0">
        {instagram.map((ig, index) => (
          <div className="relative group" key={index}>
            <Image
              src={ig.image}
              alt="ig image"
              quality={100}
              width={800}
              height={800}
            />
            <Link href={ig.link}>
              <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center bg-black bg-opacity-0 md:group-hover:bg-opacity-40">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/ig.png"}
                  width={32}
                  height={32}
                  alt="instagram icon"
                  className="hidden h-fit ig-icon group-hover:block"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Instagram
