import Image from 'next/image';
import Link from "next/link";
import { ShoppingCategoryItemProps } from './constants';


interface ShoppingCategoryProps {
  banner_list: Array<ShoppingCategoryItemProps>;
  title: string;
}

const ShoppingCategory = (props: ShoppingCategoryProps) => {
  const { banner_list, title } = props;

  return (
    <div className="container mt-12 md:mt-20">
      {title.length > 0 && (
        <p className="mb-8 md:mb-12 text-[#2C3E2F] text-2xl md:text-[32px] text-center font-dmsans font-medium md:-tracking-[1.6px]">{title}</p>
      )}
      <div className={`grid grid-cols-1 gap-4 md:grid-cols-${props.banner_list.length}`}>
        {banner_list.map((shopping, index) => (
          <div className="relative overflow-hidden bg-no-repeat bg-cover" key={index}>
            <Link href={shopping.link}>
              <div className="relative md:hidden">
                <Image
                  src={shopping.mobile_image}
                  alt={shopping.text + " image"}
                  quality={100}
                  width={800}
                  height={800}
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
              </div>
              <div className="hidden md:block">
                <Image
                  src={shopping.desktop_image}
                  alt={shopping.text + " image"}
                  quality={100}
                  width={600}
                  height={863}
                  className="transition duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div className="absolute w-full px-4 bottom-8 md:bottom-12 md:px-8">
                <p className="text-[28px] md:text-[32px] text-white font-dmsans font-medium -tracking-[1.2px] w-full" dangerouslySetInnerHTML={{__html: shopping.text}}></p>
                <p className="text-sm md:text-base text-white font-dmsans mt-1 mb-4 w-full -tracking-[0.36px] md:-tracking-[0.48px]" dangerouslySetInnerHTML={{__html: shopping.desc}}></p>
                <button className={`px-8 md:px-10 py-2 md:py-[14px] text-white md:text-[#2C3E2F] font-dmsans text-sm md:text-base font-medium text-center md:w-[180px] md:bg-[#FCFCF8] group inline-flex overflow-hidden transition-all relative border border-white border-solid md:border-0`}>
                  <span className="w-full h-0 md:bg-[#2C3E2F] absolute left-0 bottom-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                  <span className="z-10 w-full transition-colors duration-500 ease-in-out md:group-hover:text-white">Shop now</span>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ShoppingCategory
