import Image from 'next/image';
import Link from 'next/link';
import { MinProductItemProps } from "./constants"
import { useEffect, useState } from 'react';
import { CURRENCY_OPTIONS } from '../Common/GlobalVars';
import { AttributeValueItem } from '@/lib/constants';
import { useAttributesStore } from '@/stores/useAttributesStore';


const MinProduct = (props: MinProductItemProps) => {
  const { attribute_value_list, main_image, image, name, msrp, sales_price, special_price, url, brand, slug, product_variant_list, attrId, customName } = props;
  const { attributes, getAttributes } = useAttributesStore();
  const [showImage, setShowImage] = useState(image);
  const [selectedAttr, setSelectedAttr] = useState(attrId);
  const [attributesData, setAttributesData] = useState(getAttributes());

  useEffect(() => {
    if (selectedAttr !== null || attrId !== null) {
      const variants = product_variant_list.filter(variant => variant.attribute_value_list.indexOf(attrId !== null ? attrId : selectedAttr !== null ? selectedAttr : 0) !== -1);

      if (variants.length > 0) {
        setShowImage(variants[0].cart_image);
      } else {
        setShowImage(image);
      }
    } else {
      setShowImage(image);
    }
  }, [selectedAttr, attrId, image, product_variant_list]);

  useEffect(() => {
    setAttributesData(getAttributes());
  }, [getAttributes, attributes]);

  const render_product_color = () => {
    const color_list: Array<AttributeValueItem> = [];

    attributesData.data.map((attributes, index) => {
      if (attributes.slug === "color") {
        const filtered = attributes.attribute_values.filter((attribute) => attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        if (filtered.length > 0) {
          filtered.map((color) => {
            color_list.push(color);
          });
        }
      }
    });

    if (color_list.length > 1) {
      return (
        <div className="flex flex-wrap justify-start mt-2">
          {color_list.map((color, index) => (
            <Image
              src={color.image}
              alt={color.name + " image"}
              width={14}
              height={14}
              quality={100}
              key={index}
              className={`rounded-full cursor-pointer my-2 mr-4`}
              onClick={() => setSelectedAttr(color.id)}
            />
          ))}
        </div>
      )
    } else {
      return (<></>)
    }
  };

  return (
    <div className="">
      <Link href={url !== null && url !== undefined && url.length > 0 ? url : "/product/" + slug}>
        <div className='relative pt-[100%] block mini-product'>
          <Image
            src={showImage}
            alt={name}
            quality={100}
            width={190}
            height={190}
            className='absolute top-0 left-0 object-contain w-full h-full align-bottom transition duration-500'
          />
          <Image
            src={main_image}
            alt={name}
            quality={100}
            width={190}
            height={190}
            className='absolute top-0 left-0 object-contain w-full h-full align-bottom transition duration-500 opacity-0'
          />
        </div>
        <p className="text-sm font-dmsans font-medium text-[#C6C6C6] mt-2 underline">{brand}</p>
        <p className="text-sm text-[#54565A] line-clamp-3 md:line-clamp-2 my-2 font-medium text-ellipsis font-dmsans md:h-10 capitalize">
          {customName === null ? name : name + " - " + customName}
        </p>
        <div className="flex justify-start">
          <div className="text-[#54565A] text-base font-medium flex items-end">
            {process.env.NEXT_PUBLIC_CURRENCY}{Array.isArray(special_price) ? special_price[0].toLocaleString("en-US", CURRENCY_OPTIONS) + " ~ " + process.env.NEXT_PUBLIC_CURRENCY + special_price[1].toLocaleString("en-US", CURRENCY_OPTIONS) : special_price.toLocaleString("en-US", CURRENCY_OPTIONS)}
            {msrp !== undefined && msrp !== null && special_price < msrp && (
              <p className="text-[#C0C0C0] text-sm font-medium line-through ml-2">{process.env.NEXT_PUBLIC_CURRENCY}{msrp.toLocaleString("en-US", CURRENCY_OPTIONS)}</p>
            )}
            {(msrp === undefined || msrp === null || msrp === 0) && special_price < sales_price && (
              <p className="text-[#C0C0C0] text-sm font-medium line-through ml-2">{process.env.NEXT_PUBLIC_CURRENCY}{sales_price.toLocaleString("en-US", CURRENCY_OPTIONS)}</p>
            )}
          </div>
        </div>
      </Link>
      {brand === "MOOIMOM" && render_product_color()}
    </div>
  )
}

export default MinProduct
